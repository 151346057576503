import React, { useRef, useEffect, useState } from 'react';
import './TarjetaCumpleanos.css';
import musica from './cumple.mp3';

// Importar imágenes
import imagen2 from './2.jpg';
import imagen3 from './3.jpg';
import imagen4 from './4.jpg';



const TarjetaCumpleanos = () => {
  const audioRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Array de imágenes
  const imagenes = [imagen2, imagen3, imagen4];

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.play().catch(error => {
        console.log('Autoplay prevented by browser', error);
      });
    }

    // Cambiar imagen cada 3 segundos
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % imagenes.length);
    }, 5000);

    return () => clearInterval(interval); // Limpiar intervalo al desmontar
  }, []);

  const habilitarSonido = () => {
    const audio = audioRef.current;
    setIsMuted(false);
    audio.muted = false;
    audio.play();
  };

  return (
    <div className="tarjeta">
      <h1>¡Feliz Cumpleaños!</h1>
      <p>Espero que tengas un día increíble, gracias amor por tanto</p>
      <img
        src={imagenes[currentImageIndex]}
        alt={`Imagen de cumpleaños ${currentImageIndex + 1}`}
        className="imagen-cumple"
      />
      <audio ref={audioRef} src={musica} autoPlay muted={isMuted} />
      {isMuted && (
        <button onClick={habilitarSonido} className="boton-reproduccion">
          {/* Icono se creará con CSS */}
        </button>
      )}
    </div>
  );
};

export default TarjetaCumpleanos;
